<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary">
            Divide Studio Indonesia
          </h2>
        </b-link>
        <b-card-text>
          Please enter the domain you want to access
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form"
            @submit.prevent="login"
          >
            <b-form-group
              label="Domain"
            >
              <validation-provider
                #default="{ errors }"
                name="Domain"
                rules="required"
              >
                <b-form-input
                  v-model="domain"
                  :state="errors.length > 0 ? false:null"
                  autofocus
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Enter
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      domain: '',
      required,
    }
  },
  created() {
    localStorage.setItem('apiUrl', 'https://api.medicku.divideindonesia.com/api')
    localStorage.setItem('appName', 'MedicKu')
    window.location.href = '/login'
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$http.post('https://portal.divideindonesia.com/api/auth/domain',{ Domain : this.domain  })
          .then(res => {
            localStorage.setItem('apiUrl',res.data.apiUrl)
            localStorage.setItem('appName',res.data.name)
            window.location.href = '/login'
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    }
  },
}
/* eslint-enable */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
